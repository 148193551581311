import Image from "next/image";
import Logo from "../../public/img/logo.png";

const news = `+++ Tierversand kann derzeit normal stattfinden +++ ACHTUNG liebe Tierfreunde, bitte IMMER berücksichtigen, dass ALLE Fahrzeuge die Tiere transportieren, nicht klimatisiert (NICHT beheizt / gekühlt) sind und auf den Ladeflächen nahezu die Außentemperaturen herrschen. Bitte auch die Nachttiefsttemperaturen beachten! +++ Bitte berücksichtigen das die Abhol- und Lieferzeiten Richtzeiten sind und nicht garantiert werden können +++ Verpflegung für Tiersendung !! Tiere müssen IMMER für 48 Stunden ausreichend mit entsprechendem Futter versorgt sein!! Ausreichend flüssigkeitshaltige Lebensmittel z.B. Gurke, Äpfel, Karotte usw. beigeben +++ Am liebsten reisen Ihre Lieblinge natürlich mit uns! :) Euer Gardow Logistik Tierversand-Team +++`;

const Header = () => {
  return (
    <header>
      <div className="justify-center hidden md:flex">
        <Image src={Logo} />
      </div>
      <div className="flex md:mt-10 overflow-hidden text-sm md:text-lg md:rounded-lg h-[30px] md:h-[45px]">
        <div className="flex items-center flex-shrink h-full px-2 font-bold text-white bg-red-700">
          <span className="hidden mr-1 md:inline-flex">Tierversand</span> News
        </div>
        <div className="flex items-center flex-1 h-full px-2 overflow-hidden font-bold text-black bg-red-600 whitespace-nowrap">
          <div className="animate-marquee">{news}</div>
        </div>
      </div>
    </header>
  );
};

export { Header };

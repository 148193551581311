import Link from "next/link";
import Pattern from "../../public/img/pattern-bg-footer.png";
import FooterTop from "../../public/img/footer-top.png";

const footerNav = [
  {
    name: "Kontakt",
    href: "/",
  },
  {
    name: "Impressum",
    href: "/",
  },
  {
    name: "AGB Tierversand",
    href: "/",
  },
  {
    name: "Widerrufsbelehrung",
    href: "/",
  },
  {
    name: "Datenschutzerklärung",
    href: "/",
  },
];

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <div className="w-full">
      <div className="h-[12px] w-full" style={{ background: `url(${FooterTop.src}) repeat-x` }} />
      <div
        className={`w-full flex justify-center items-center text-white ${className ?? ""}`}
        style={{ backgroundImage: `url(${Pattern.src})` }}
      >
        <div className="grid w-full max-w-5xl gap-6 pt-4 text-sm divide-x md:grid-cols-4 divide-black/50">
          <div className="px-4">
            <div className="text-lg font-bold text-white/40">Navigation</div>
            <div className="flex flex-col mt-4 gap-y-0.5">
              {footerNav.map(({ name, href }) => (
                <Link href={href}>
                  <a className="hover:underline">{name}</a>
                </Link>
              ))}
            </div>
          </div>

          <div className="px-4 pb-10">
            <div className="text-lg font-bold text-white/40">Kontakt</div>
            <div className="flex flex-col mt-4 gap-y-0.5">
              <div>Kleintierkurier.de</div>
              <div>Benzstraße 15</div>
              <div>D-71701 Schwieberdingen</div>
              <div>Tel.: +49 7150 97899 44</div>
              <div>
                Email: <a href="mailto:info@kleintierkurier.de">info@kleintierkurier.de</a>
              </div>
            </div>
          </div>

          <div className="px-4">
            <div className="text-lg font-bold text-white/40">Credits</div>
            <div className="flex flex-col mt-4 gap-y-0.5">
              <div>© 2023 Gardow Logistik</div>
              <div className="mt-4">Tel.: +49 7150 97899 44</div>
            </div>
          </div>

          <div className="px-4">
            <div className="text-lg font-bold text-white/40">Suche</div>
            <div className="flex flex-col mt-4 gap-y-0.5"></div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center py-4 text-sm text-white underline">
        <a
          className="cursor-pointer"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Nach oben
        </a>
      </div>
    </div>
  );
};

export { Footer };

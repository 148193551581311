import Image from "next/image";
import React, { Ref } from "react";
import { FieldError } from "react-hook-form";

export interface TextInputProps {
  label: string;
  placeholder: string;
  name: string;
  defaultValue?: string;
  description?: string;
  type?: "text" | "email" | "tel" | "number";
  error?: FieldError;
  leftLabel?: string;
  disbled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  icon?: string;
  className?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      placeholder,
      name,
      error,
      description,
      type = "text",
      leftLabel,
      disbled,
      defaultValue,
      min,
      max,
      step,
      icon,
      className,
    },
    ref
  ) => {
    return (
      <div className={className}>
        <label>
          <div className="text-sm font-semibold">{label}</div>
          <div
            className={`flex w-full border ${error ? "border-red-600" : "border-gray-400"
              } ${disbled && "bg-gray-100"} rounded`}
          >
            {leftLabel && (
              <div className="pl-2 flex items-center rounded-l pointer-events-none select-none">
                {leftLabel}
              </div>
            )}
            <div className={`min-w-0 flex-grow flex ${icon && "pl-2"}`}>
              {icon && (
                <Image src={icon} className="ml-2" width="32px" height="32px" />
              )}
              <input
                readOnly={disbled}
                name={name}
                ref={ref}
                placeholder={placeholder}
                type={type}
                defaultValue={defaultValue}
                min={min}
                max={max}
                step={step}
                className={`min-w-0 flex-grow appearance-none rounded ${disbled && "cursor-not-allowed bg-gray-100 focus:outline-none"
                  } py-1 px-2`}
              />
            </div>
          </div>
        </label>
        <div className="text-sm">{description}</div>
        <div className="text-sm text-red-600">{error?.message}</div>
      </div>
    );
  }
);

export { TextInput };

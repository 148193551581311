import HeadingBg from "../public/img/sidebar-heading.png";

export const SidebarHeading = ({ children }: { children: string }) => {
  return (
    <div className="pt-4">
      <div
        className="w-[249px] h-[53px] -ml-[16px] text-white text-lg flex pt-1.5 font-bold justify-center"
        style={{ background: `url(${HeadingBg.src}) no-repeat` }}
      >
        {children}
      </div>
    </div>
  );
};

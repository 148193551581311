import Link from "next/link";
import { SidebarHeading } from "./sidebar-heading";
import { TextInput } from "./text-input";

const menuItems: { label: string; href: string }[] = [
  { label: "Zurück zur Startseite", href: "/" },
  { label: "Tierversand für Tauben / Geflügel", href: "/gefluegelversand-ist-eingeschraenkt-wieder-moeglich" },
  { label: "Online-Preisrechner Tierversand", href: "/online-preisrechner" },
  { label: "Online-Auftrag Tierversand", href: "/auftrag-tierversand" },
  { label: "Tierversand Verpackungen im Shop", href: "/shop-fuer-verpackungen" },
  { label: "Postleitzahl Überprüfung Tierversand", href: "/postleitzahl-ueberpruefung-tierversand" },
  { label: "Tierversand / Tiertransport Neuigkeiten", href: "/neuigkeiten-tiertransport" },
  { label: "Tierversandrichtlinien / Häufig gestellte Fragen zum Tierversand", href: "/fragen" },
  { label: "Mögliche Tage für Tierversand", href: "/moegliche-tiertransporttage" },
  { label: "Adress- und Tierversandaufkleber", href: "/adress-tierversandaufkleber" },
  { label: "Preisliste Tierversand", href: "/preisliste-tierversand" },
  { label: "Zonenbeschreibung", href: "/zonenbeschreibung" },
  { label: "Wirtschaftszentren für Tierversand", href: "/wz" },
  { label: "Sendungsverfolgung Tierversand", href: "/sendungsverfolgung" },
  { label: "Anfrage Tierversand", href: "/anfrage-tierversand" },
  { label: "AGB Tierversand", href: "/agbs" },
  { label: "Kontakt", href: "/kontakt" },
  { label: "Downloadbereich Tiertransport", href: "downloadbereich-tiertransport" },
];
export const Sidebar = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="text-sm bg-white/10 rounded-xl w-full md:w-[233px]">
        <SidebarHeading>Navigation</SidebarHeading>
        <div className="pb-4 divide-y-2 divide-green-600/50">
          {menuItems.map(({ label, href }) => (
            <Link href={href ?? "#"}>
              <a
                key={label}
                className="block py-2 pl-6 pr-6 text-white hover:[text-shadow:rgb(22_163_74)_1px_0_20px] hover:text-green-600 font-medium transition-all duration-200 ease-in-out "
              >
                {label}
              </a>
            </Link>
          ))}
        </div>
      </div>

      <div className="text-sm bg-white/10 rounded-xl w-[233px]">
        <SidebarHeading>Suche</SidebarHeading>
        <div className="p-4">
          {/* @ts-expect-error */}
          <TextInput />
        </div>
      </div>
    </div>
  );
};

import Head from "next/head";
import { Footer } from "./sections/footer";
import { Header } from "./sections/header";
import { Sidebar } from "./sidebar";
import Pattern from "../public/img/pattern-bg.png";
import PatternMain from "../public/img/pattern-bg-main.png";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col items-center text-gray-700" style={{ backgroundImage: `url(${Pattern.src})` }}>
      <Head>
        <title>Günstiger Tierversand | kleintierkurier.de</title>
        <meta property="og:title" content="Günstiger Tierversand | kleintierkurier.de" key="title" />
        <meta
          property="og:description"
          name="description"
          content="Wir versenden Ihre Tiere schnell und sicher. Mit unserem Versandkonfigurator buchen Sie Ihren Tierersand in wenigen Augenblicken"
        />
        <meta
          name="keywords"
          content="Tierversand, Tiertransport, Tiere versenden, Tiere verschicken, Lebendtierversand, Günstiger Tierversand"
        />
      </Head>
      <div className="w-full max-w-[1012px] md:py-6">
        <Header />
        <div className="flex flex-col-reverse gap-12 p-1 mt-6 mb-12 md:flex-row md:p-0">
          <Sidebar />
          <main className="flex-1">
            <div
              className="flex-1 py-5 text-white rounded-[8px] px-[60px]"
              style={{ backgroundImage: `url(${PatternMain.src})` }}
            >
              <div className="mx-auto prose-sm prose prose-invert prose-p:text-[#eceef1] prose-a:font-semibold max-w-none marker:text-white prose-hr:border-[#008000] prose-hr:my-2 prose-h1:text-xl">
                {children}
              </div>
            </div>
            <div className="text-[11px] text-white mt-2 tracking-[1px]">Alle Preise inkl. der gesetzl. MwSt.</div>
          </main>
        </div>
      </div>

      <Footer className="mt-auto" />
    </div>
  );
};

export { Layout };
